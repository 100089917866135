import { CookieStorage } from 'cookie-storage';
import { EventSourcePolyfill } from 'event-source-polyfill';
import cookieStorage from 'utils/cookie';

const cookie: CookieStorage | null = cookieStorage();

const sseClient = (url: string) => {
  const token = cookie?.getItem('accessToken');

  return new EventSourcePolyfill(url, {
    headers: {
      Authorization: token ? `Token ${token}` : '',
    },
  });
};

export default sseClient;
