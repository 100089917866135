/* eslint-disable camelcase */
import { UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';

const ProfileMenu = ({ history, dispatch }) => {
  const { first_name, last_name } = useSelector((state) => state.user.profile);

  const onLogout = useCallback(() => {
    dispatch({
      type: 'user/LOGOUT',
      payload: {
        redirect: () => history.push('/system/login'),
      },
    });
  }, [dispatch, history]);

  const onRedirectToProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const getInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
    }
    return '';
  };

  const menu = {
    items: [
      {
        label: 'Profile',
        key: 'profile',
        icon: <UserOutlined />,
        onClick: onRedirectToProfile,
      },
      { type: 'divider' },
      {
        label: 'Sign out',
        key: 'sign-out',
        icon: <i className="fe fe-log-out" />,
        onClick: onLogout,
      },
    ],
    selectable: false,
  };

  return (
    <Dropdown menu={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <div className={styles.userAvatar}>
          {getInitials(first_name, last_name)}
        </div>
      </div>
    </Dropdown>
  );
};

ProfileMenu.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default ProfileMenu;
