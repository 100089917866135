import generateRequestActions from 'redux/factories/generateRequestActions';

const actions = {
  ...generateRequestActions(['fetch'], 'sessions', 'scan_sessions'),
  ...generateRequestActions(['fetch'], 'sessions', 'scan_session_by_id'),
  ...generateRequestActions(['fetch'], 'sessions', 'scan_session_by_id_short'),
  ...generateRequestActions(
    ['create', 'update', 'reopen'],
    'sessions',
    'session',
  ),
  ...generateRequestActions(
    ['fetch', 'update', 'void', 'cancel', 'delete'],
    'scan',
    'scan_by_id',
  ),
  ...generateRequestActions(['update', 'invalidate'], 'scan', 'tube'),
  ...generateRequestActions(['delete'], 'scan', 'tube'),
  ...generateRequestActions(['update'], 'scan', 'selected_code'),
  ...generateRequestActions(['fetch'], 'scan', 'session_id'),
  ...generateRequestActions(['fetch'], 'session', 'company_info'),
  ...generateRequestActions(['check'], 'scans', 'scanner_status_by_id'),
  CHANGE_SESSION_DATA: 'session/CHANGE_SESSION_DATA',
  FETCH_SESSION_ID_REQUEST: 'session/FETCH_SESSION_ID_REQUEST',
  RESET_SCAN: 'scan/RESET_SCAN',
  UPDATE_POPOVER_STATE: 'rackboard/POPOVER_STATE',
  ...generateRequestActions(['upload'], 'activeSession', 'scan'),
  FETCH_SCAN_BY_SSE: 'activeSession/FETCH_SCAN_BY_SSE',
  ...generateRequestActions(['fetch'], 'scans', 'active_scans'),
  ...generateRequestActions(['reassign'], 'sessions', 'intake_item'),
  ...generateRequestActions(['move'], 'session', 'poolscan'),
  ...generateRequestActions(['fetch'], 'sessions', 'service_scan_sessions'),
};

export default actions;
