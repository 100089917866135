/* eslint-disable indent */
import uniqBy from 'lodash.uniqby';
import { NoteType } from 'models/note.model';
import actions from './actions';

const initialState = {
  items: [],
  isLoading: false,
  total: 0,
  // offset: 0,
  type: null,
};

type DataType = {
  results: NoteType[];
  count: number;
};

type PayloadType = {
  data: DataType;
  type: string;
};

type ActionType = {
  type: string;
  payload: PayloadType;
};

const notesReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actions.FETCH_NOTES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_NOTES_SUCCESS: {
      const { data, type } = action.payload;
      return {
        ...state,
        // TODO: refactor
        items:
          type === 'poolscan'
            ? uniqBy([...state.items, ...data.results], 'id')
            : data.results,
        total: data.count,
        isLoading: false,
        // offset
      };
    }
    case actions.FETCH_NOTES_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.CREATE_NOTE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        items: [...state.items, action.payload.data],
        // total: state.total + 1,
      };
    }
    case actions.CREATE_NOTE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default notesReducer;
