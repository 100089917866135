import { notification } from 'antd';
import partition from 'lodash.partition';
import sortBy from 'lodash.sortby';

export const getSampleId = (displaySampleId) => {
  const regexp = /(.+)-T-.+/;
  const sampleId = displaySampleId?.match(regexp);
  if (sampleId?.[1]) {
    return sampleId[1];
  }
  // TODO: expand error, use Sentry
  // throw new Error(`sample ID undefined for the ${displaySampleId}`);
  return notification.error({
    message: 'Failure!',
    description: `Sample ID undefined for the ${displaySampleId}`,
    duration: 10,
  });
};

export const getSampleTarget = (displaySampleId) => {
  const regexp = /.+-T-(.+)/;
  const target = displaySampleId?.match(regexp);
  if (target?.[1]) {
    return target[1];
  }
  // TODO: expand error, use Sentry
  // throw new Error(`sample target undefined for the ${displaySampleId}`);
  return notification.error({
    message: 'Failure!',
    description: `Sample target undefined for the ${displaySampleId}`,
    duration: 10,
  });
};

const isRelatedTo = (displaySampleId, sampleId) => {
  return displaySampleId.includes(sampleId);
};

const addTargetToEachRecord = (samples) => {
  return samples.map((sample) => {
    const infcovidTarget = getSampleTarget(sample.display_sample_id);
    return {
      ...sample,
      infcovidTarget,
      children: sample.children?.map((record) => {
        return {
          ...record,
          infcovidTarget,
        };
      }),
    };
  });
};

export const getINFCOVIDFormattedSamples = (samples) => {
  let formattedSamples = {};
  let restSamplesArr = [...addTargetToEachRecord(samples)];

  while (restSamplesArr.length > 0) {
    const sampleId = getSampleId(restSamplesArr[0].display_sample_id);

    const [relatedSamples, rest] = partition(restSamplesArr, (sample) =>
      isRelatedTo(sample.display_sample_id, sampleId),
    );

    if (rest >= restSamplesArr) {
      // TODO: expand error, use Sentry
      // throw new Error(
      //   `A problem with ${restSamplesArr[0].display_sample_id} ${restSamplesArr[0].sample_id}`,
      // );
      notification.error({
        message: 'Not all results shown!',
        description: `A problem with ${restSamplesArr[0].display_sample_id} ${restSamplesArr[0].sample_id}`,
        duration: null,
      });
      break;
    }

    formattedSamples = {
      ...formattedSamples,
      [sampleId]: sortBy(relatedSamples, 'display_sample_id'),
    };

    restSamplesArr = rest;
  }

  return formattedSamples;
};
