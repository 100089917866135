import axiosClient from 'utils/axiosClient';
import { constants } from 'utils/constants';
import handleError from 'utils/errorOutput';

export const fetchScanById = async (scanId) => {
  try {
    const scan = await axiosClient.get(`/scans/pool/${scanId}/`);

    return scan;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchActiveScans = async ({ existingScans, sessionId }) => {
  try {
    const scans = await axiosClient.get(
      `scans/sessions/${sessionId}/refresh/`,
      {
        params: {
          pool_ids: existingScans?.join(),
        },
      },
    );

    return scans;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateTube = async ({ id, data }) => {
  try {
    const tube = await axiosClient.patch(`/scans/tubes/${id}/`, { ...data });

    return tube;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const deleteTube = async ({ tubeId, scanId }) => {
  try {
    const tube = await axiosClient.delete(
      `/scans/tubes/${tubeId}/from/${scanId}/`,
    );
    return tube;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const deleteScan = async ({ id }) => {
  try {
    return await axiosClient.delete(`/scans/pool/${id}/`);
  } catch (error) {
    throw new Error(error);
  }
};

export const updateScan = async ({ data, id }) => {
  try {
    const scan = await axiosClient.patch(`/scans/pool/${id}/`, {
      ...data,
    });

    return scan;
  } catch (error) {
    throw new Error(error?.response?.data.non_field_errors);
  }
};

export const uploadScan = async (payload) => {
  const { file, onSuccess, onError } = payload?.options;

  try {
    const formData = new FormData();
    formData.append('poolscan', file);
    formData.append('scan_type', constants.scanType.pool);
    formData.append('activeSessionId', payload.sessionId);

    const uploadedScan = await axiosClient.post(
      `/miri/poolscan/upload/`,
      formData,
      {
        baseURL: process.env.REACT_APP_ADMIN_API_URL,
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    onSuccess(uploadedScan);
    return uploadedScan;
  } catch (error) {
    onError(error);
    throw new Error(handleError(error));
  }
};

export const movePoolscan = async (payload) => {
  try {
    return await axiosClient.patch('/scans/pool/move/', {
      ...payload,
    });
  } catch (error) {
    throw new Error(handleError(error));
  }
};
