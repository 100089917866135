import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const { routerAnimation } = useSelector((state) => state.settings);

  useEffect(() => {
    setTimeout(
      () => window.scrollTo(0, 0),
      routerAnimation === 'none' ? 0 : 350,
    );
  }, [pathname, routerAnimation]);

  return null;
}
