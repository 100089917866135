import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';
import { constants } from 'utils/constants';

export const fetchRackScans = async (query) => {
  try {
    const rackScans = await axiosClient.get('/scans/rack/', {
      params: { ...query },
    });

    return rackScans;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const fetchRackScan = async (id) => {
  try {
    const rackScan = await axiosClient.get(`/scans/rack/${id}/`);

    return rackScan;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const updateRackScan = async ({ id, ...values }) => {
  try {
    const rackScan = await axiosClient.patch(`/scans/rack/${id}/`, {
      ...values,
    });

    return rackScan;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const deleteRack = async ({ id }) => {
  try {
    return await axiosClient.delete(`/scans/rack/${id}/`);
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const uploadScan = async (payload) => {
  const { file, onSuccess, onError } = payload;

  try {
    const formData = new FormData();
    formData.append('poolscan', file);
    formData.append('scan_type', constants.scanType.rack);
    formData.append('activeSessionId', '');

    const uploadedScan = await axiosClient.post(
      `/miri/poolscan/upload/`,
      formData,
      {
        baseURL: process.env.REACT_APP_ADMIN_API_URL,
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
    onSuccess(uploadedScan);
    return uploadedScan;
  } catch (error) {
    onError(error);
    throw new Error(handleError(error));
  }
};
