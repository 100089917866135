import { NoteType } from 'models/note.model';
import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

export const fetchNotes = async (payload: string[]) => {
  try {
    const notes = await axiosClient.get('/common/note/', {
      params: {
        barcodes: payload.join(','),
        limit: 200,
      },
    });

    return notes;
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const createNote = async (payload: NoteType) => {
  try {
    const note = await axiosClient.post('/common/note/create/', {
      ...payload,
    });

    return note;
  } catch (error) {
    throw new Error(handleError(error));
  }
};
