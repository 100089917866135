/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

export const constants = {
  dateFormat: 'll z',
  dateTimeFormat: 'lll z',
  companies: {
    itemsLoadingCount: 25,
  },
  runs: {
    itemsLoadingCount: 25,
  },
  pools: {
    itemsLoadingCount: 25,
  },
  poolsByCompany: {
    itemsLoadingCount: 3,
  },
  scanSessions: {
    itemsLoadingCount: 20,
  },
  poolRacks: {
    itemsLoadingCount: 25,
  },
  analysisRuns: {
    itemsLoadingCount: 25,
    initialDates: {
      from: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
  },
  inventory: {
    itemsLoadingCount: 25,
  },
  scan: {
    emptyPoolId: 'AAA',
  },
  scanStatuses: {
    populating: 'POPULATING',
    started: 'STARTED',
    invalid: 'INVALID',
    completed: 'COMPLETED',
    error: 'ERROR',
  },
  sessionStatuses: {
    started: 'STARTED',
    completed: 'COMPLETED',
  },
  tubes: {
    // default
    blank: {
      status: 'BLANK',
      countedForPool: false,
      countedForPoolRack: false,
    },
    missing: {
      status: 'MISSING',
      countedForPool: false,
      countedForPoolRack: false,
    },
    valid: {
      status: 'VALID',
      countedForPool: true,
      countedForPoolRack: true,
    },
    deleted: {
      status: 'NULL',
      countedForPool: false,
      countedForPoolRack: false,
    },
    positiveControl: {
      status: 'POSITIVE_CONTROL',
      countedForPool: false,
      countedForPoolRack: false,
    },
    negativeControl: {
      status: 'NEGATIVE_CONTROL',
      countedForPool: false,
      countedForPoolRack: false,
    },
    pooling: {
      status: 'POOLING',
      countedForPool: false,
      countedForPoolRack: true,
    },
    // invalid statuses
    invalid: {
      status: 'INVALID',
      countedForPool: true,
      countedForPoolRack: true,
    },
    empty: {
      status: 'EMPTY',
      countedForPool: true,
      countedForPoolRack: true,
    }, // A
    insufficient: {
      status: 'INSUFFICIENT',
      countedForPool: true,
      countedForPoolRack: true,
    }, // B
    improperCollection: {
      status: 'IMPROPER_COLLECTION',
      countedForPool: true,
      countedForPoolRack: true,
    }, // C
    contamination: {
      status: 'CONTAMINATION',
      countedForPool: true,
      countedForPoolRack: true,
    }, // D
    incorrectLetters: ['D', 'E', 'F'],
    referenceTubePosition: 'F8',
  },
  poolsByRun: {
    itemsLoadingCount: 100,
  },
  intakeLog: {
    itemsLoadingCount: 25,
  },
  shippingBy: [
    {
      text: 'FedEx',
      label: 'FedEx',
      value: 'FedEx',
    },
    {
      text: 'UPS',
      label: 'UPS',
      value: 'UPS',
    },
    {
      text: 'Dropoff',
      label: 'Dropoff',
      value: 'Dropoff',
    },
    {
      text: 'Drop Box',
      label: 'Drop Box',
      value: 'Drop Box',
    },
  ],
  shippingConditions: [
    {
      text: 'Satisfactory',
      label: 'Satisfactory',
      value: 'Satisfactory',
    },
    {
      text: 'Unsatisfactory',
      label: 'Unsatisfactory',
      value: 'Unsatisfactory',
    },
  ],
  shippingViolations: [
    'Individually-bagged',
    'Contains PHI',
    'No biohazard bag',
    'No absorbent sheet',
    'Leaky or wet bag',
    'Damaged tube',
    'Wrong cap',
    'Expired',
  ],
  sampleConditions: [
    {
      text: 'Acceptable',
      label: 'Acceptable',
      value: 'Acceptable',
    },
    {
      text: 'Unacceptable',
      label: 'Unacceptable',
      value: 'Unacceptable',
    },
    {
      text: 'Other',
      label: 'Other (see Comments)',
      value: 'Other',
    },
  ],
  runStatuses: {
    pendingTemplate: 'TEMPLATE_GENERATION',
    qpcr: 'QPCR',
    pendingResults: 'RES_IMPORT',
    analysis: 'ANALYSIS',
    review: 'REVIEW',
    finalizing: 'FINALIZING',
    published: 'PUBLISHED',
    error: 'ERROR',
  },
  rerunActions: {
    reflex: 'REFLEX_SC',
    rerun: 'RERUN',
  },
  poolResults: {
    notDetected: 'NOT_DETECTED',
    detected: 'DETECTED',
    inconclusive: 'INCONCLUSIVE',
    invalid: 'INVALID',
    inProgress: 'IN_PROGRESS',
    rejected: 'REJECTED',
  },
  reflexList: {
    itemsLoadingCount: 25,
  },
  A: 'A'.charCodeAt(0),
  invalidateCodes: [
    {
      id: 1,
      code: 'A',
      reason: 'Empty',
      color: '#ffffff',
      status: 'EMPTY',
    },
    {
      id: 2,
      code: 'B',
      reason: 'Quantity Not Sufficient',
      color: '#cacaca',
      status: 'INSUFFICIENT',
    },
    {
      id: 3,
      code: 'C',
      reason: 'Improper Sample Collection',
      color: '#ff0000',
      status: 'IMPROPER_COLLECTION',
    },
    {
      id: 4,
      code: 'D',
      reason: 'Contamination',
      color: '#ff0000',
      status: 'CONTAMINATION',
    },
  ],
  controlTypes: [
    {
      label: 'Positive',
      value: 'POSITIVE_CONTROL',
    },
    {
      label: 'Negative',
      value: 'NEGATIVE_CONTROL',
    },
  ],
  tubeTypes: {
    pooling: 'Pooling',
    individual: 'Individual',
    reserved: 'Reserved',
    grouping: 'Grouping',
    grouped: 'Grouped',
    ungrouped: 'Ungrouped',
  },
  ampStatuses: {
    inconclusive: 'inconclusive',
  },
  disabledPathsSession: {
    intake: 'intake-receipt-log',
    session: '/session/',
  },
  tipsModalStatuses: {
    hide: 'hide',
  },
  barcodeTitles: {
    cqValue: 'CQ Value',
    poolscan: 'Pool ID',
    rackscan: 'Pool Rack Name',
    run: 'Run Name',
    completedRun: 'Completed Run',
    reflex: 'Reflex Comparison',
    result: 'Finalized Result',
  },
  chartTypes: {
    bar: 'bar',
    pie: 'pie',
  },
  intakeDashboardTabs: [
    { title: 'Diagnostic count', value: 'diagnostic_count' },
    { title: 'Pool count', value: 'pool_count' },
    { title: 'Tube count', value: 'tube_count' },
    { title: 'Rejected count', value: 'rejected_count' },
    { title: 'Positivity rate', value: 'positivity_rate' },
    { title: 'Total count', value: 'total_count' },
  ],
  reflexResults: [
    {
      title: 'Detected',
      tagValue: 'detected',
      value: 'DETECTED',
      color: 'red',
    },
    {
      title: 'Inconclusive',
      tagValue: 'inconclusive',
      value: 'INCONCLUSIVE',
      color: 'orange',
    },
    {
      title: 'Invalid',
      tagValue: 'invalid',
      value: 'INVALID',
      color: 'default',
    },
    {
      title: 'Not detected',
      tagValue: 'notDetected',
      value: 'NOT_DETECTED',
      color: 'green',
    },
    {
      title: 'Rejected',
      tagValue: 'rejected',
      value: 'REJECTED',
      color: 'default',
    },
  ],
  intakeDashboard: [
    'total_counts',
    'total_samples_by_date',
    'aggregate_counts_by_date',
  ],
  intakeDashboardStats: [
    { title: 'Total diagnostics count', value: 'total_diagnostic_count' },
    { title: 'Total pools count', value: 'total_pools_counts' },
    { title: 'Total tube count', value: 'total_tube_counts' },
    { title: 'Total rejected count', value: 'total_rejected_count' },
    { title: 'Total count', value: 'total_counts' },
  ],
  scanType: {
    pool: 'SCN_POOL',
    rack: 'SCN_RACK',
  },
  defaultRunsRange: {
    // TODO: .format('YYYY-MM-DD') ???
    from: moment().subtract(7, 'days'),
    to: moment(),
  },
  chartColors: [
    '#390099',
    '#9E0059',
    '#FF0054',
    '#FF5400',
    '#FFBD00',
    '#7209B7',
    '#560BAD',
    '#3F37C9',
    '#4361EE',
    '#4895EF',
    '#1A4301',
    '#245501',
    '#538D22',
    '#E89005',
    '#EC7505',
    '#D84A05',
    '#F42B03',
    '#E70E02',
    '#16003d',
    '#480041',
  ],
  testTypes: {
    covid19: 'covid19',
    mpx: 'mpx',
    infcovid19: 'infcovid19',
    infcovidrsv: 'infcovidrsv',
  },
  assays: {
    INFA: 'FluA',
    INFB: 'FluB',
    SC2: 'COVID-19',
  },
  runTypes: {
    Pools: 'Pools',
    Reflex: 'Reflex',
    Diagnostic: 'Diagnostic',
    Validation: 'Experiment',
    'R&D': 'R&D',
    Other: 'Other',
  },
};
