import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadTimeline } from 'services/timeline';
import actions from './actions';

export function* callLoadTimeline({ payload }) {
  try {
    const response = yield call(loadTimeline, payload.id);

    yield put({
      type: actions.LOAD_TIMELINE_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (error) {
    yield put({ type: actions.LOAD_TIMELINE_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_TIMELINE_REQUEST, callLoadTimeline)]);
}
