import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import notesActions from 'redux/notes/actions';
import { fetchInfo } from 'services/search';
import actions from './actions';

export function* callFetchInfo({ payload }) {
  try {
    const { data } = yield call(fetchInfo, payload);

    yield put({
      type: actions.FETCH_INFO_SUCCESS,
      payload: { data },
    });

    yield put({
      type: notesActions.FETCH_NOTES_REQUEST,
      payload: {
        data: [payload.search],
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_INFO_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_INFO_REQUEST, callFetchInfo)]);
}
