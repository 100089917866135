import axiosClient from 'utils/axiosClient';
import handleError from 'utils/errorOutput';

export const fetchTestParams = async () => {
  try {
    return await axiosClient.get('/scans/classificators/intake/params/');
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const fetchRunMethods = async () => {
  try {
    return await axiosClient.get('/runs/classificators/run/methods/');
  } catch (error) {
    throw new Error(handleError(error));
  }
};

export const fetchResultList = async () => {
  try {
    const resultList = await axiosClient.get(`/pools/test-result-choices/`);
    return resultList;
  } catch (error) {
    throw new Error(handleError(error));
  }
};
